.p-tabview {
  .p-tabview-nav {
    display: inline;

    li {
      display: inline-block;

      margin-bottom: 2px; // compensating for p-tabview-nav-link margin bottom -2px

      max-width: 100%;

      &.p-highlight {
        .p-tabview-nav-link {
          border-color: $blue;
        }
      }

      &.p-tabview-ink-bar {
        display: none; // I have no idea what this is for but it renders just a line
      }

      .p-tabview-nav-link {
        margin-bottom: 0 !important; // The default primeng margin was -2px but that was separating the active hightlighted border from the ul gray border
      }
    }
  }

  .p-tabview-nav-link {
    text-decoration: none !important; // Avoid label underline as the bottom border is enough
  }

  .button-panel-header {
    .p-tabview-nav-link {
      border: 0 !important; // Avoid bottom border on button headers
      padding-top: 3px !important; // To render the active highlight border
      top: 0.5rem;
    }
  }

  &.tabview-inset {
    .p-tabview-panels {
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &.tabview-transparent {
    .p-tabview-nav {
      background-color: transparent !important;
      border-color: #adb5bd;
      padding-bottom: 1rem;

      .p-tabview-nav-link {
        background-color: transparent !important;
      }
    }

    .p-tabview-panels {
      background-color: transparent !important;
    }
  }
}
