.p-panel {
  &.p-panel-padding-0 {
    .p-panel-header {
      padding: 0.5rem 0 !important;
    }

    .p-panel-content {
      padding: 0 !important;
    }
  }
}
