.p-sidebar {
  &.sidebar-content-h-100 {
    .p-sidebar-content {
      height: 100%;
      overflow: hidden;
    }
  }

  &.p-sidebar-md {
    min-width: 400px;
    max-width: 90vw;
  }

  &.p-sidebar-lg {
    min-width: 600px;
    max-width: 90vw;
  }

  &.p-sidebar-xl {
    min-width: 900px;
    max-width: 90vw;
  }

  &.p-sidebar-xxl {
    min-width: 1200px;
    max-width: 90vw;
  }
}

.p-sidebar.sidebar-hide-header-and-footer {
  .p-sidebar-header,
  .p-sidebar-footer {
    display: none;
  }

  .p-sidebar-header + .p-sidebar-content {
    padding-top: 1rem; // instead of 0
  }
}
