/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// the default primeng UI scale is a bit large; this can fix it but not on FF (https://stackoverflow.com/questions/1156278/how-can-i-scale-an-entire-web-page-with-css)
@use "sass:math";

$zoom: 1;

$height: math.div(100vh, $zoom);

body {
  font-family: Arial, sans-serif;
  zoom: $zoom;

  background-color: #f8f9fa;

  @media print {
    background-color: transparent !important;
  }
}

input,
button,
select,
optgroup,
textarea {
  line-height: normal; // cancelling bootstrap reboot line-height: inherit;
}

label {
  margin-bottom: 0.3rem; // cancelling bootstrap reboot line-height: inherit;
}

.cursor-auto {
  cursor: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cpp-shell-wrapper {
  position: relative;
  height: $height;
  display: flex;
  flex-direction: column;

  .cpp-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
  }

  .cpp-shell-header {
    flex-grow: 0;
  }

  .cpp-shell-content {
    flex-grow: 1;
  }

  .cpp-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 0;
    z-index: 1;
    padding: 0 1rem 0.5rem;

    &.sidebar-enabled {
      //max-width: calc(100vw - 19rem); // src/app/@shared/navigation/navigation.component.scss .pinned-items
    }
  }

  .cpp-content {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 1rem;
    position: relative;

    &:not(.cpp-content-with-sidebar) {
      &:first-child {
        padding-top: 3.5rem;
      }
    }

    &.cpp-content-with-sidebar {
      display: flex !important;

      .cpp-content-main {
        width: 100%;
        padding-top: 3.5rem;

        &.sidebar-enabled {
          width: calc(
            100vw - 21rem
          ); // src/app/@shared/navigation/navigation.component.scss .pinned-items plus 2rem extra for the padding
        }
      }
    }
  }

  @media print {
    .cpp-shell-header,
    .cpp-footer {
      display: none !important;
    }
  }
}

.text-bold-600 {
  font-weight: 600 !important;
}

.c-warning {
  color: $orange;
}

.trashed {
  text-decoration: line-through;
}

.dependencies {
  margin: 1rem 0 0 0;
  padding: 0;
  position: relative;
  left: -1.5rem;
  max-height: 40rem;

  .list-style-type-relation {
    list-style-type: disc;
  }

  .list-style-type-record {
    list-style-type: square;
  }
}

// p-dropdown, p-autocomplete, etc
body > .p-overlay {
  max-width: min(50%, 40rem); // min between 30% of the screen width and 40 rem - width of md sidebar

  line-height: normal;
}
