.p-chips {
  line-height: normal;

  .p-chips-multiple-container {
    gap: normal;
  }

  .p-chips-token {
    max-width: 100%;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

.p-chips-token-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
