.picture {
  overflow: hidden;
  //background-color: lightgray;

  display: inline-block;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
