td {
  .p-carousel {
    .p-carousel-content {
      .p-carousel-prev,
      .p-carousel-next {
        margin: 0;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.p-carousel.ng-invalid {
  border: 1px solid #f44336;
  border-radius: 3px;
}
