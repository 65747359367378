.p-dialog-content {
  img {
    max-width: 100%;
  }
}

.p-dialog {
  .p-dialog-footer {
    padding-top: 1rem !important;
  }
}
