// TODO: th content pushes the sort icons out of the element
// TODO: .p-column-resizer is not visible

.p-datatable table {
  width: 100%;
  table-layout: fixed;
}

tr.unread {
  font-weight: bold;
}

tr.ng-invalid,
td.ng-invalid {
  border: 2px solid #f44336 !important; // 2px to compensate for border collapsing
  border-radius: 3px;
}

// TODO: Tried pulling the resizer handle slightly to the right but it just goes under the next th
/*
.p-datatable .p-column-resizer {
  right: -5px !important;
}
*/

.p-datatable-resizable-fit .p-datatable-wrapper {
  overflow-x: hidden !important; // prevent horizontal scroll on fit tables when rendering a border with ng-invalid or sticking the header
}

//class text-ellipsis causes the dropdown to be included inside the cell
// and does not apply position absolute
td {
  &.p-cell-editing {
    overflow: unset !important;
  }
}
