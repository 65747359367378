/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

//Fixes Warning: 1 rules skipped due to selector errors: .custom-file-input:lang(en) ~ .custom-file-label -> unmatched pseudo-class :lang
$custom-file-text: ();

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries

@layer bootsrap {
  @import "bootstrap/scss/bootstrap";
  // Importing only some underlying Bootstrap utilities and layout to avoid the components
  //@import "bootstrap/scss/functions";
  //@import "bootstrap/scss/variables";
  //@import "bootstrap/scss/mixins";
  //@import "bootstrap/scss/root";
  //@import "bootstrap/scss/reboot";
  //@import "bootstrap/scss/grid";
  //@import "bootstrap/scss/utilities";
}

// Font awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "quill/dist/quill.snow.css";

// Theme
@import "theme/preloader";
@import "theme/avatar";
@import "theme/picture";
@import "theme/carousel";
@import "theme/tables";
@import "theme/autocomplete";
@import "theme/datepicker";
@import "theme/dropdown";
@import "theme/multiselect";
@import "theme/chips";
@import "theme/menus";
@import "theme/dialog";
@import "theme/news";
@import "theme/sidebar";
@import "theme/paginator";
@import "theme/tabview";
@import "theme/card";
@import "theme/tooltip";
@import "theme/panel";

// Theme customization
@import "theme/theme";

// Layers order; give primeng higher priority otherwise bootstrap reset breaks component styles
@layer bootstrap, primeng;
