.p-tieredmenu .p-submenu-right .p-submenu-list {
  left: auto !important;
  right: 100%;
}

.p-menu {
  width: 15rem !important; // overriding default 12.5 rem
}

.p-menubar .p-submenu-list {
  width: 15rem !important; // overriding default 12.5 rem
}
