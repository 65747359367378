// The fixed height is 200px. Going with more than this would
/*
.p-autocomplete-panel {
  max-height: 35rem !important;
}
*/

.p-autocomplete {
  line-height: normal;

  .p-autocomplete-panel {
    max-width: 100%;
    left: 0 !important;
  }

  .p-autocomplete-multiple-container {
    gap: normal;
  }

  .p-autocomplete-token {
    max-width: 100%;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    > div {
      max-width: calc(100% - 1.125rem);
      padding-top: 0.25rem;
    }

    .p-autocomplete-token-icon {
      margin-left: 0;
    }
  }
}

.p-autocomplete-panel {
  max-height: 600px !important;
}

.p-autocomplete-token-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-autocomplete-item {
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
