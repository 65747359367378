.p-dropdown {
  line-height: normal;
}

.w-100 {
  .p-dropdown {
    width: 100%;
  }
}

.dropdown-toggle::after {
  display: none;
}

.readonly {
  .p-dropdown,
  .p-dropdown-label {
    cursor: text;
  }

  .p-dropdown-trigger {
    display: none;
  }
}

.p-dropdown-items-wrapper {
  max-height: 600px !important;
}

.p-dropdown-item {
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.p-dropdown-trigger {
  background-color: $blue !important;
  color: $white !important;
  border: 1px solid $blue;
}

.p-dropdown-trigger:hover {
  background: $hover-blue !important;
  border-color: $hover-blue !important;
}

.p-dropdown {
  &.blank {
    .p-placeholder {
      background-color: $blue !important;
    }
  }

  &.text-white {
    .p-placeholder {
      color: $white !important;
    }
  }

  &.blink {
    .p-placeholder {
      //animation: fade 1000ms infinite;
      //-webkit-animation: fade 1000ms infinite;
    }
  }
}

@media print {
  .p-dropdown-trigger,
  .p-dropdown-clear-icon {
    display: none !important;
  }
}
