.p-datepicker-trigger {
  background-color: $blue !important;
  border-color: $blue !important;
}

.p-datepicker-trigger:hover {
  background: $hover-blue !important;
  border-color: $hover-blue !important;
}

.p-datepicker-trigger:enabled:focus {
  box-shadow: 0 0 0 0.2rem $focus-blue;
}

.p-datepicker-trigger:enabled:active {
  background: $active-blue !important;
  border-color: $active-blue !important;
}
