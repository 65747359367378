.avatar {
  border-radius: 50%;
  overflow: hidden;
  background-color: lightgray;

  // TODO: Style this properly for header and dropdowns
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
