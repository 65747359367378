.p-multiselect {
  line-height: normal;

  .p-multiselect-label {
    white-space: normal !important;

    .p-multiselect-token {
      max-width: 100%;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}

.p-multiselect-items-wrapper {
  max-height: 600px !important;
}

.p-multiselect-token-label {
  max-width: 100%;
}

.p-multiselect-item {
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.p-multiselect-trigger {
  background-color: $blue !important;
  color: $white !important;
  border: 1px solid $blue;
}

.p-multiselect-trigger:hover {
  background: $hover-blue !important;
  border-color: $hover-blue !important;
}
